import { uniq } from 'lodash';
import Vuex from 'vuex';
import Vue from 'vue';

import config from '@/config';
import localeFr from '@/config/locales/fr.json';
import localeEn from '@/config/locales/en.json';

Vue.use(Vuex);

const token = localStorage.getItem(`${config.appName}_token`);
const selectedProgramCode = localStorage.getItem(`${config.appName}_selectedProgramCode`);
let user;

try {
  user = localStorage.getItem(`${config.appName}_user`)
    ? JSON.parse(localStorage.getItem(`${config.appName}_user`))
    : '';
} catch (err) {
  console.error(err);
}

function getExtendedRoles(role, state) {
  let myRoles = [];
  if (
    state.roles
    && state.roles[role]
    && state.roles[role].inherits
    && Array.isArray(state.roles[role].inherits)
  ) {
    myRoles = myRoles.concat(state.roles[role].inherits);
    state.roles[role].inherits.forEach(r => {
      myRoles = myRoles.concat(getExtendedRoles(r, state));
    });
  }
  return uniq(myRoles);
}

/*
const vuexLocal = new VuexPersistence({
  key: `vuex_${config.appName}`,
  storage: window.localStorage,
});

*/
const store = new Vuex.Store({
  //  plugins: [vuexLocal.plugin],
  modules: {},
  state: {
    user,
    token,
    translations: { fr: localeFr, en: localeEn },
    locale:
      (user && user.locale)
      || localStorage.getItem(`${config.appName}_locale`)
      || config.defaultLocale,
    roles: {
      USER: { inherits: [] }, // can access the app
      BO: { inherits: ['USER'] }, // can access the ADMIN
      DOCTOR: { inherits: ['BO'] },
      ADMIN: { inherits: ['DOCTOR'] },
      SUPERADMIN: { inherits: ['ADMIN'] },
    },
    userExtendedRoles: [],
    data: {
      resources: [],
      tasks: [],
    },
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    initialAssessmentQuizzes: [],
    modules: [],
    selectedModule: null,
    selectedMediaTab: 'video',
    selectedSettingsTab: 'perso',
    mediaModalContent: null,
    programs: [],
    selectedProgram: null,
    selectedProgramCode,
    favorites: [],
    studyCode: null,
  },
  mutations: {
    resources(state, resources) {
      state.data.resources = resources;
    },

    tasks(state, tasks) {
      state.data.tasks = tasks;
    },

    roles(state, appRoles) {
      //  state.roles = appRoles;
    },

    extendedRoles(state) {
      let myRoles
        = state.user && state.user.roles && state.user.roles.isArray ? state.user.roles : [];
      myRoles.forEach(role => {
        myRoles = myRoles.concat(getExtendedRoles(role, state));
      });
      state.userExtendedRoles = myRoles;
    },

    user(state, currentUser) {
      state.user = currentUser;
      state.locale = currentUser.locale;
      localStorage.setItem(`${config.appName}_user`, JSON.stringify(currentUser));
    },

    currentLocale(state, locale) {
      state.locale = locale;
      localStorage.setItem(`${config.appName}_locale`, locale);
    },

    token(state, appToken) {
      this._vm.$http.defaults.headers.common.Authorization = `Bearer ${appToken}`;
      this._vm.$http.defaults.headers.Authorization = `Bearer ${appToken}`;
      state.token = appToken;
      localStorage.setItem(`${config.appName}_token`, appToken);
    },

    commitData(state, key, value) {
      state.data[key] = value;
    },

    toggleSidebarDesktop(state) {
      const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow);
      state.sidebarShow = sidebarOpened ? false : 'responsive';
    },

    toggleSidebarMobile(state) {
      const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow);
      state.sidebarShow = sidebarClosed ? true : 'responsive';
    },

    initialAssessmentQuizzes(state, list) {
      state.initialAssessmentQuizzes = (list || []).sort((a, b) => {
        if (a.order < b.order) {
          return -1;
        }

        if (a.order > b.order) {
          return 1;
        }

        return a.id < b.id ? -1 : 1;
      });
    },

    set(state, [variable, value]) {
      state[variable] = value;
    },

    modules(state, modules) {
      state.modules = modules;
    },

    selectedModule(state, selectedModule) {
      state.selectedModule = selectedModule;
    },

    selectedMediaTab(state, selectedMediaTab) {
      state.selectedMediaTab = selectedMediaTab;
    },

    selectedSettingsTab(state, selectedSettingsTab) {
      state.selectedSettingsTab = selectedSettingsTab;
    },

    mediaModalContent(state, mediaModalContent) {
      state.mediaModalContent = mediaModalContent;
    },

    programs(state, programs) {
      state.programs = programs;
    },

    studyCode(state, studyCode) {
      state.studyCode = studyCode;
    },

    selectedProgram(state, selectedProgram) {
      state.selectedProgram = selectedProgram;

      state.selectedProgramCode = selectedProgram && selectedProgram.code;
      localStorage.setItem(
        `${config.appName}_selectedProgramCode`,
        selectedProgram && selectedProgram.code,
      );
    },

    favorites(state, favorites) {
      state.favorites = favorites;
    },
  },
  getters: {},
  actions: {
    logout({ commit, state }) {
      delete App.user;
      commit('user', '');
      commit('token', '');
      commit('selectedProgram', null);
      localStorage.clear();
      return true;
    },

    user({ commit }, currentUser) {
      commit('user', currentUser);
      commit('extendedRoles');
      return true;
    },

    refreshUser({ commit, dispatch }) {
      const q = this._vm.$http.get('/api/auth/user');
      q.then(res => {
        App.user = res.data.user;
        localStorage.setItem(`${config.appName}_user`, JSON.stringify(res.data.user));
        commit('user', res.data.user);
        commit('extendedRoles');
      }).catch(err => {
        console.warn('refresh error', err);
        this._vm.$notify({
          title: err.response ? err.response.data : err,
          type: 'warning',
        });
        if (err.response && err.response.status === 404) {
          dispatch('logout');
        }
      });
      return q;
    },

    getTranslations(context) {
      const p = this._vm.$http.get(`/api/translations/${context.state.locale}`);

      p.then(res => {
        context.commit('translations', res.data);
      }).catch(err => {
        console.warn(err);
      });

      return p;
    },

    getInitialAssessmentData(context) {
      const p = this._vm.$http.get(`/api/quiz?isInitialAssessment=1&programId=${context.state.selectedProgram && context.state.selectedProgram.id}`);
      p.then(res => {
        context.commit('initialAssessmentQuizzes', res.data && res.data.body ? res.data.body : []);
      }).catch(err => {
        console.warn(err);
      });

      return p;
    },

    changeLocale(context, locale) {
      context.commit('currentLocale', locale);
      // context.dispatch('getTranslations');
    },
    // eslint-disable-next-line
    init(context) {
      //  return context.dispatch('getTranslations');
    },

    async getModules(context) {
      const { data } = await this._vm.$http.get(
        `/api/module?sort[order]=DESC&filters[programId]=${context.state.user.programId}`
      );
      if (data && data.body) {
        context.commit('modules', data.body);
      }
    },

    async getProgram(context) {
      const { data } = await this._vm.$http.get(`/api/program/${context.state.user.programId}`);
      if (data && data.body) {
        context.commit(
          'selectedProgram',
          data.body,
        );
      }
      return context.commit('programs', data.body);
    },

    async selectProgram(context, programCode = null) {
      let program;
      const userProgramCode = context.state.user && context.state.user.programId;

      if (programCode || userProgramCode) {
        context.commit('selectedProgram', null);
        const { data } = await this._vm.$http.get(`/api/program/${programCode || userProgramCode}`);
        program = data.body;
        context.commit('selectedProgram', program);
      } else {
        await context.dispatch('getProgram');
        if (context.state.selectedProgram) {
          console.warn(context.state.selectedProgram);
          program = context.state.selectedProgram;
        }
      }

      return program;
    },

    async getFavorites(context) {
      const { data } = await this._vm.$http.get('/api/media/favorite');
      if (data && data.body) {
        context.commit('favorites', data.body);
      }
    },
  },
});

export default store;
