<template>
  <v-offline
    online-class="online"
    offline-class="offline"
    @detected-condition="onOnlineStatusChanged"
  >
    <!--
    <div v-if="!isOnline" class="c-app page-offline flex-column align-items-center justify-content-center text-center">
      <h1 class="mb-5">DOCLIVI</h1>
      <h4>You do not have the internet connection at the moment</h4>
    </div>
    -->
    <div>
      <notifications></notifications>
      <router-view></router-view>
    </div>
  </v-offline>
</template>

<script>
import { App } from '@capacitor/app';
import { VOffline } from 'v-offline';

export default {
  mounted() {
    App.addListener('backButton', () => {
      $('#mediaModal').modal('hide');
      this.$router.go(-1);
    });
  },
  methods: {
    onOnlineStatusChanged(e) {
      this.isOnline = e;
    },
  },
  components: {
    VOffline,
  },
  data: () => ({
    isOnline: true,
  }),
};
</script>
<style lang="scss">
@import 'assets/sass/main';

.vue-notifyjs.notifications {
  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }
  .list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }
  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }

  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}
</style>
